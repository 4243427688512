import React, { useCallback, useState, useEffect } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import cx from 'classnames';
import { ArrowButton } from './arrowButton';

export const CardSlider = ({ children }) => {
  // Embla Setup
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    dragFree: false,
    align: 'start',
    containScroll: true,
    speed: 20,
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi]);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <div className="w-full relative">
      <div ref={emblaRef} className="w-5/6 md:w-cardSlider md:mx-auto overflow-x-hidden">
        <div className="flex">{children}</div>
      </div>
      <ArrowButton
        className={cx(
          'absolute top-1/2 transform -translate-y-1/2 left-0 z-10 transition-colors duration-75 hidden md:block cursor-pointer',
          {
            'opacity-50': !prevBtnEnabled,
          }
        )}
        direction="left"
        onClick={scrollPrev}
        disabled={!prevBtnEnabled}
      />
      <ArrowButton
        className={cx(
          'absolute top-1/2 transform -translate-y-1/2 right-0 z-10 transition-colors duration-75 cursor-pointer',
          {
            'opacity-50': !nextBtnEnabled,
          }
        )}
        direction="right"
        onClick={scrollNext}
        disabled={!nextBtnEnabled}
      />
    </div>
  );
};
