import React, { useCallback, useState, useEffect } from 'react';
import cx from 'classnames';
import { ModuleWrapper } from '../moduleWrapper';
import { useEmblaCarousel } from 'embla-carousel/react';

const ImageGallery = ({ config, galleryItems, layout }) => {
  // Embla Setup
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    dragFree: false,
    align: 'start',
    containScroll: true,
    speed: 20,
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi]);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
    emblaApi.on('select', (event) => {
      setSlideIndex(emblaApi.selectedScrollSnap());
    });
  }, [emblaApi, onSelect]);

  const galleryClasses = cx({
    'col-start-2 col-end-14 md:col-start-3 md:col-end-13': true,
    'lg:col-start-2 lg:col-end-8 pr-4': layout === 'left',
    'lg:col-start-8 lg:col-end-14 pl-4': layout === 'right',
  });

  return (
    <ModuleWrapper {...config} className="grid grid-cols-14 gap-y-20">
      <div className={galleryClasses}>
        <div ref={emblaRef}>
          <div className="flex">
            {galleryItems.map((item) => (
              <div key={item._key} className="w-full" style={{ flex: '0 0 auto' }}>
                <div className="w-full relative aspect-w-11 aspect-h-10">
                  <img
                    src={item.asset.url}
                    alt={item.alt}
                    className="absolute inset-0 object-cover"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-1 w-full flex justify-between">
          {/* Caption */}
          <div>
            <span className={galleryItems.length < 2 && 'hidden'}>{slideIndex + 1} / </span>
            {galleryItems[slideIndex].alt}
          </div>
          {/* Nav */}
          <div className={`flex gap-3 ${galleryItems.length < 2 && 'hidden'}`}>
            <div
              className={`z-10 transition-colors duration-75 cursor-pointer ${
                !prevBtnEnabled && 'opacity-0'
              }`}
              role="button"
              direction="right"
              onClick={scrollPrev}
              onKeyDown={scrollPrev}
              disabled={!prevBtnEnabled}
            >
              Previous
            </div>
            <div
              className={`z-10 transition-colors duration-75 cursor-pointer ${
                !nextBtnEnabled && 'opacity-50'
              }`}
              role="button"
              direction="right"
              onClick={scrollNext}
              onKeyDown={scrollNext}
              disabled={!nextBtnEnabled}
            >
              Next
            </div>
          </div>
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default ImageGallery;
