import React from 'react';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export const ImageGallery = ({ children }) => { 
  return (
    <div className="flex flex-col">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
        {children}
      </Swiper>
      {/* Bottom Bar */}
      <div className="w-full flex justify-between">
        {/* Caption */}
        <div>Caption</div>
        {/* Nav */}
        <div className="hidden col-start-1 col-end-15 justify-between row-start-2 self-center lg:flex">
          <div
            className={`z-10 transition-colors duration-75`}
            direction="right"
            onClick={Swiper.slideNext()}
            onKeyDown={Swiper.slideNext()}
            role="button"
            aria-pressed="false"
            tabindex="0"
          >
            Next
          </div>
        </div>
      </div>
    </div>
  );
};
