import React, { useState } from 'react';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import { ModuleWrapper } from '../moduleWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';
import { DotLogo } from '../../ui/dotLogo';
import { PageButton } from '../../ui/links';

// Hooks & State
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// SVG
import IconPlus from '../../../svg/plus.svg';

import { accordionReveal, defaultTransition } from '../../../lib/animation';

const Section = ({ title, textContent, _key, expanded, setExpanded }) => {
  const isOpen = _key === expanded;
  return (
    <motion.div>
      <div
        className={`text-h4-mob lg:text-h4-mob py-3 border-b border-doc-text flex items-center justify-between text-xl cursor-pointer ${
          !textContent && 'pointer-events-none'
        }`}
        onClick={() => setExpanded(isOpen ? false : _key)}
        onKeyDown={() => setExpanded(isOpen ? false : _key)}
        role="button"
        aria-pressed="false"
        tabIndex="0"
      >
        {title}
        {textContent && (
          <motion.div animate={{ rotate: isOpen ? 135 : 0 }} transition={{ ...defaultTransition }}>
            <IconPlus className="stroke-doc-text text-doc-text w-3 lg:w-6" />
          </motion.div>
        )}
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            variants={accordionReveal}
            initial="closed"
            animate="open"
            exit="closed"
            className="overflow-hidden"
          >
            {textContent && (
              <PortableTextBlock text={textContent} className="prose py-4 max-w-none" />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const AccordionSet = ({ config, heading, accordionSections, callToAction }) => {

  const breakpoints = useBreakpoint();

  const [expanded, setExpanded] = useState(0);
  
  return (
    <ModuleWrapper {...config} className="grid grid-cols-14 gap-y-12">
      <div className="col-start-2 col-end-14 md:col-start-3 md:col-end-13 lg:col-start-2 lg:col-end-7 lg:flex lg:flex-col lg:justify-between lg:items-start">
        <div className="flex gap-x-4 items-baseline">
          <DotLogo />
          <div className="font-heading text-h3-mob lg:text-h3">{heading}</div>
        </div>
        {!breakpoints.md && callToAction.showButton && (
          <PageButton page={callToAction.page} linkText={callToAction.linkText} />
        )}
      </div>
      <div className="col-start-2 col-end-14 md:col-start-3 md:col-end-13 lg:col-start-8 lg:col-end-14">
        <AnimateSharedLayout>
          {accordionSections.map((section) => (
            <Section key={section._key} {...section} expanded={expanded} setExpanded={setExpanded} />
          ))}
        </AnimateSharedLayout>
      </div>
      {breakpoints.md && callToAction.showButton && (
        <div className="col-start-2 col-end-14 md:col-start-3 md:col-end-13 lg:col-start-2 row-start-3">
          <PageButton linkText={callToAction.linkText} page={callToAction.page} />
        </div>
      )}
    </ModuleWrapper>
  );
};

export default AccordionSet;
