import React from 'react';
import SVG from 'react-inlinesvg'; // eslint-disable-line
import { ModuleWrapper } from '../moduleWrapper';

const LogoGrid = ({ config, items }) => {
  return (
    <ModuleWrapper
      {...config}
      className={`px-gutter grid grid-cols-1 md:gap-x-16 ${
        items.length > 2 ? 'logo-grid md:grid-cols-3' : 'md:grid-cols-3'
      } `}
    >
      {items.length === 2 && (
        <div className="col-span-1"></div>
      )}
      {items.map((item) => {
        const { _key, asset, alt } = item;
        return (
          <div
            className="col-span-1 w-full flex flex-col items-center justify-start relative pb-card-square"
            key={_key}
          >
            <p className="block pb-2 border-b border-doc-text w-full mb-8 absolute top-0 left-0 xl:text-h4">
              {alt}
            </p>
            <SVG // eslint-disable-line
              className="client-svg absolute top-1/2 left-0 transform -translate-y-1/2 w-4/5 h-auto fill-doc-text"
              cacheRequests={true}
              loader={<span>Loading...</span>}
              onError={(error) => console.log(error.message)}
              src={asset.url}
              title="React"
            />
          </div>
        );
      })}
    </ModuleWrapper>
  );
}

export default LogoGrid;
