import React from 'react';
import { Link } from 'gatsby';
import { ModuleWrapper } from '../moduleWrapper';
import { DotLogo } from '../../ui/dotLogo';
//import { PageLink } from '../../ui/links';
import { PageButton } from '../../ui/links';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const ServicesGrid = ({ title, config, callToAction, serviceCards }) => {
  return (
    <ModuleWrapper {...config} className="grid grid-cols-14 gap-y-20">
      {/* Header */}
      <div className="col-start-2 col-end-14 row-start-1">
        <div className="flex gap-x-4 items-center">
          <DotLogo />
          <div className="text-h3-mob lg:text-h3">{title}</div>
        </div>
      </div>
      {/* Call To Action Button */}
      {callToAction.showButton === true && (
        <div className="row-start-3 lg:row-start-1 col-start-2 col-end-14 lg:col-start-10 lg:flex lg:justify-end">
          <PageButton linkText={callToAction.linkText} page={callToAction.page}  />
        </div>
      )}
      <div className="col-start-2 col-end-14 row-start-2 grid md:auto-rows-fr grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
        {serviceCards.map((service, index) => {
          const { title, features, featured } = service;
          return (
            <div
              key={service._key}
              className={`md:col-span-1 p-6 rounded-md md:grid md:grid-rows-2 card-box-shadow transition-all duration-500 group text-black ${
                featured ? 'bg-bc-green' : 'bg-white hover:bg-bc-green'
              }`}
            >
              <div className="flex flex-col justify-between items-start border-b border-black pb-6">
                <div className="text-h3 lg:text-h3 leading-heading mb-20 md:mb-6 2xl:mb-20 3xl:mb-28">
                  {title && title}
                </div>
                <Link to={`/contact`} className={`${featured ? 'button-card-featured' : 'button-card'}`}>Book</Link>
              </div>
              {features && (
                <PortableTextBlock text={features} className="pt-4 mb-8 md:mb-0 card-list" />
              )}
            </div>
          );
        })}
      </div>
    </ModuleWrapper>
  );
};

export default ServicesGrid;
