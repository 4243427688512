import React from 'react';
import cx from 'classnames';
import { ModuleWrapper } from '../moduleWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const TextContent = ({ config, text, centerText, layout }) => {
  const textClasses = cx({
    'prose col-start-2 col-end-14 max-w-none md:col-start-3 md:col-end-13  lg:prose-lg 2xl:prose-xl 3xl:prose-2xl': true,
    'text-center': centerText,
    'lg:col-start-8 lg:col-end-14': layout === 'left',
    'lg:col-start-2 lg:col-end-8': layout === 'right',
  });

  return (
    <ModuleWrapper {...config} className="grid grid-cols-14">
      <PortableTextBlock text={text} className={textClasses} />
    </ModuleWrapper>
  );
};

export default TextContent;
