import React from 'react'

const PageHeader = ({ title }) => {
  return (
    <div className="w-full grid grid-cols-14 mt-20 lg:mt-24 mb-8 lg:mb-24">
      <h1 className="col-start-2 col-end-12 lg:col-end-10 2xl:col-end-8 leading-heading font-heading text-h1-mob md:text-h2 lg:text-h1">
        {title && title}
      </h1>
    </div>
  );
}

export default PageHeader
