import React from 'react';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { Link } from 'gatsby';
import cx from 'classnames';
import useScrollPosition from '@react-hook/window-scroll';

// SVG
import Logo from '../../svg/logo.svg';

const HomeLinkMobile = ({ headerPinned, navOpen }) => {
  const scrolledY = useScrollPosition(5);

  const { scrollY, scrollYProgress } = useViewportScroll(); // eslint-disable-line
  const y = useTransform(scrollY, [0, 400], [100, 0]);
  const scrollPercent = useTransform(y, (value) => `${value}%`); // eslint-disable-line

  const logoClasses = cx({
    'transform rounded-full transition-all duration-500 w-6 h-6': true,
    'scale-nav bg-black': navOpen,
    'bg-doc-text ': !navOpen,
  });
  return (
    <>
      <motion.div
        className=""
        style={{ width: scrollPercent, minWidth: '190px', opacity: scrolledY < 399 ? '1' : '0' }}
      >
        <Link to="/" className={'w-full'}>
          <Logo className="w-full h-auto text-doc-text fill-doc-text" />
        </Link>
      </motion.div>
      <div className="absolute left-gutter top-4" style={{ opacity: scrolledY < 399 ? '0' : '1' }}>
        <Link to="/">
          <div className={logoClasses}></div>
        </Link>
      </div>
    </>
  );
};


const HomeLinkDesktop = ({ homeHeader, headerPinned, onClose }) => {
  const { scrollY, scrollYProgress } = useViewportScroll(); // eslint-disable-line
  const y = useTransform(scrollY, [0, 400], [100, 0]);
  const scrollPercent = useTransform(y, (value) => `${value}%`); // eslint-disable-line


  return (
    <motion.div      
      style={{ width: homeHeader ? scrollPercent : '', minWidth: '190px' }}
    >
      <Link to="/" className={'w-full'}>
        <Logo className="w-full h-auto text-doc-text fill-doc-text" />
      </Link>
    </motion.div>
  );
};

const SiteLinkMobile = ({ headerPinned, navOpen }) => {
  const logoClasses = cx({
    'transform rounded-full transition-all duration-500 w-6 h-6': true,
    'scale-nav bg-black': navOpen,
    'bg-doc-text ': !navOpen,
  });
  return (
    <div>
      {/* Show Logo */}
      <div>
        <Link to="/">
          <div className={logoClasses}></div>
        </Link>
      </div>
    </div>
  );
};

const SiteLinkDesktop = ({ headerPinned, onClose }) => {
  const { scrollY, scrollYProgress } = useViewportScroll(); // eslint-disable-line
  const y = useTransform(scrollY, [0, 400], [100, 0]);
  const scrollPercent = useTransform(y, (value) => `${value}%`); // eslint-disable-line

  
  return (
    <motion.div className="" style={{ minWidth: '190px' }}>
      <Link to="/" className={'w-full'}>
        <Logo className="w-full h-auto text-doc-text fill-doc-text" />
      </Link>
    </motion.div>
  );
};

export { HomeLinkDesktop, HomeLinkMobile, SiteLinkDesktop, SiteLinkMobile };
