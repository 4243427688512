import React from 'react';

export const MenuToggle = ({ isOpen, reverseHeader, ...props }) => {  
  return (
    <div {...props}>
      <svg
        width="27"
        height="13"
        viewBox="0 0 27 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-current"
      >
        <line x1="4.21468e-08" y1="0.5" x2="26.8701" y2="0.5" />
        <line x1="2.10734e-08" y1="12" x2="26.8701" y2="12" />
      </svg>
    </div>
  );
};
