import React, { useState } from 'react';
import { Link } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { accordionReveal } from '../../../lib/animation';

const BlogHeader = ({ title, date, categories, currentCategory, showCats }) => {

  const breakpoints = useBreakpoint();

  const [categoryExpanded, setCategoryExpanded] = useState(0);  

  return (
    <div className="w-full grid grid-cols-14 lg:grid-cols-3 lg:gap-x-24 lg:px-gutter gap-y-6 mt-20 mb-8 lg:mb-24">      
      {showCats && showCats.showCategories  === true && (
        <div className="bg-white lg:bg-transparent p-4 lg:p-0 rounded-md card-box-shadow lg:shadow-none col-start-2 col-end-14 lg:row-start-1 lg:col-start-1 lg:col-end-4">
        {/* Categories List Mobile */}
        <div
          className="flex justify-between cursor-pointer lg:hidden"
          onClick={() => setCategoryExpanded(!categoryExpanded)}
          onKeyDown={() => setCategoryExpanded(!categoryExpanded)}
          role="button"
          aria-pressed="false"
          tabindex="0"
        >
          All Posts
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
            <path
              d="M9.50112 1.72183V17.2782M9.50112 17.2782L17.2793 9.5M9.50112 17.2782L1.72295 9.5"
              stroke="black"
              stroke-width="1.5"
            />
          </svg>
        </div>
        <AnimatePresence initial={false}>
          {categoryExpanded && (
            <motion.div
              variants={accordionReveal}
              initial="closed"
              animate="open"
              exit="closed"
              className="flex flex-col lg:flex-row lg:gap-6"
            >
              <Link
                to={`/insights`}
                className={`py-2 lg:py-0 ${
                  'All Posts' === currentCategory ? 'text-bc-grey' : 'text-doc-text'
                }`}
              >
                All
              </Link>
              {categories.map((category) => {
                const { title } = category.node;
                return (
                  <Link
                    to={`/insights`}
                    className={`py-2 lg:py-0 ${
                      title === currentCategory ? 'text-bc-grey' : 'text-doc-text'
                    }`}
                  >
                    {title}
                  </Link>
                );
              })}          
            </motion.div>
          )}
        </AnimatePresence>
        {/* Categories List Desktop */}
        {!breakpoints.md && (
          <div className="flex flex-row gap-6 justify-end">
            <Link
              to={`/insights`}
              className={`${'All Posts' === currentCategory ? 'text-bc-grey' : 'text-doc-text'}`}
            >
              All
            </Link>
            {categories.map((category) => {
              const { title } = category.node;
              return (
                <Link
                  to={`/insights`}
                  className={`${title === currentCategory ? 'text-bc-grey' : 'text-doc-text'}`}
                >
                  {title}
                </Link>
              );
            })}
          </div>
        )}
      </div>
      )}
      {/* Heading Date */}
      {title && (
        <div className="mt-16 lg:mt-0 col-start-2 col-end-12 lg:col-start-1 lg:col-end-4">
          {date && <p className="text-bg-darkAlt leading-none">{date}</p>}
          <h1 className=" leading-heading font-heading text-h1-mob md:text-h2 lg:text-h1 lg:w-5/6 2xl:w-3/5">
            {title && title}
          </h1>
        </div>
      )}
    </div>
  );
};

export default BlogHeader;
