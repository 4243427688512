import React from 'react';
import { useSiteState } from '../lib/navContext';
import '../css/main.css';
import { Script } from "gatsby";

// Components
import { Header } from '../components/nav/header';
import { Footer } from '../components/nav/footer';

const Layout = ({ children }) => {  

  const { homeHeader } = useSiteState();

  return (
    <>
      <Header />

      {/* absolute top-0 left-0 */}
      <main className={`w-full h-full ${!homeHeader && 'pt-16 lg:pt-24'}`}>
        {children}
        <Footer />
      </main>

    </>
  );
};

export default Layout;
