import React from 'react';
import { motion } from 'framer-motion';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation } from '@reach/router';

// SVG
import ArrowRight from '../../svg/arrow-r.svg';


function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export const SubscribeForm = ({ heading, placeholder }) => {
  const { pathname } = useLocation();
  return (
    <div>
      {/* Form */}
      <Formik
        initialValues={{
          email: '',
        }}
        validate={(values) => {
          const errors = {};

          if (!values.email) {
            errors.email = 'Email address is Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          fetch(pathname || '/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
              'form-name': `contact-form`,
              ...values,
            }),
          })
            .then(() => {
              setSubmitting(false);
              setStatus('success');
            })
            .catch((error) => alert(error));
        }}
      >
        {({
          errors,
          status,
          values,
          /* and other goodies */
        }) => (
          <Form className="w-full relative">
            {heading && <h4 className="text-xl pb-4 text-current border-b border-current">{heading}</h4>}
            <div className="relative">
              <Field
                type="text"
                name="email"
                placeholder={placeholder || 'Your Email'}
                className={`outline-none font-display appearance-none bg-transparent w-full border-b border-current text-current py-3 placeholder-current`}
              />
              <ErrorMessage
                name="email"
                className="absolute top-full pt-2 text-sm py-2 w-full"
                component="div"
              />
              <button
                className={`absolute right-0 top-1/2 transform -translate-y-1/2	${
                  values.email ? '' : 'pointer-events-none'
                }`}
                type="submit"
              >
                <ArrowRight className="w-6 text-current fill-current" />
              </button>
              {status === 'success' && (
                <div className="absolute top-full left-0 pt-2">
                  <motion.h2
                    className=""
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                  >
                    Thank for subscribing.
                  </motion.h2>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
