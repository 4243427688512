import React from 'react';
import Marquee from 'react-fast-marquee';
import { ModuleWrapper } from '../moduleWrapper';

// Main Component
const AwardScroller = ({ config, awards }) => {
  return (
    <ModuleWrapper {...config} className={`grid grid-cols-14 gap-y-16 lg:gap-y-40`}>
      <div className="col-start-1 col-end-15 transition-colors duration-300 w-full block bg-doc-bgAlt bg-opacity-50 text-doc-text card-box-shadow">
          <Marquee gradient={false} speed={120}>
            {awards.map((award, index) => {
              return (
                <div key={index} className="py-4 px-8 lg:px-16 xl:px-24 2xl:px-28 lg:py-4 border-r border-bg-grey">
                  {award}
                </div>
              );
            })}
          </Marquee>
        </div>
    </ModuleWrapper>
  );
};

export default AwardScroller;
