import React from 'react';
import cx from 'classnames';

// SVG
import ArrowRight from '../../svg/arrow-r.svg';
import ArrowLeft from '../../svg/arrow-l.svg';

export const ArrowButton = ({ direction = 'left', className, ...props }) => {
  return (
    <div
      className={cx(className, {
        'bg-transparent flex text-current w-8 h-12 items-center justify-center': true,
      })}
      {...props}
    >
      {direction === 'left' ? (
        <ArrowLeft className="fill-current w-6" />
      ) : (
        <ArrowRight className="fill-current w-6" />
      )}
    </div>
  );
};
