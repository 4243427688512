import React, { useCallback, useState, useEffect } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import cx from 'classnames';
import SVG from 'react-inlinesvg'; // eslint-disable-line
import { ModuleWrapper } from '../moduleWrapper';
import { DotLogo } from '../../ui/dotLogo';
import { ArrowButton } from '../../ui/arrowButton';


const ClientItem = ({item}) => { 
 return (
   <div key={item._key} className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4" style={{ flex: '0 0 auto' }}>
     <div className="w-full aspect-w-4 aspect-h-3 relative">
       
       <SVG // eslint-disable-line
         className="client-svg absolute top-1/2 transform -translate-y-1/2 w-2/5 h-auto mx-auto opacity-50 fill-doc-text"
         cacheRequests={true}
         loader={<span>Loading...</span>}
         onError={(error) => console.log(error.message)}         
         src={item.svg.asset.url}
         title="React"
       /> 
     </div>
   </div>
 );
}
const ClientSlider = ({ config, heading, sliderItems }) => {
  // Embla Setup
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    dragFree: false,
    align: 'start',
    containScroll: true,
    speed: 20,
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi]);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <ModuleWrapper {...config} className="grid grid-cols-14 gap-y-20">
      <div className={`col-start-2 col-end-14`}>
        <div className="flex gap-x-4 items-baseline">
          <DotLogo />
          <div className="font-heading text-h3-mob lg:text-h3">{heading}</div>
        </div>
      </div>
      <div className={`col-start-2 col-end-14 row-start-2`}>
        {/* Embla */}
        <div ref={emblaRef} className="w-full overflow-x-hidden relative">
          {/* Main Carousel */}
          <div className="flex">
            {sliderItems.map((item) => (
              <ClientItem key={item._key} item={item} />
            ))}
          </div>
          {/* Navigation */}
          <ArrowButton
            className={cx(
              'absolute top-1/2 transform -translate-y-1/2 left-0 z-10 transition-colors duration-75 hidden md:block cursor-pointer',
              {
                'opacity-50': !prevBtnEnabled,
              }
            )}
            direction="left"
            onClick={scrollPrev}
            disabled={!prevBtnEnabled}
          />
          <ArrowButton
            className={cx(
              'absolute top-1/2 transform -translate-y-1/2 right-0 z-10 transition-colors duration-75 cursor-pointer',
              {
                'opacity-50': !nextBtnEnabled,
              }
            )}
            direction="right"
            onClick={scrollNext}
            disabled={!nextBtnEnabled}
          />
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default ClientSlider;
