import React from 'react'
import { motion, AnimatePresence } from 'framer-motion';

// Hooks & Context
import { useNavQuery } from '../../GraphQl/useNavQuery';
import { useSiteState } from '../../lib/navContext';
import { useScrollFreeze } from '../../hooks';

// Components
import { NavLink } from './navLink';

// SVG
import Logo from '../../svg/logo.svg';
import Close from '../../svg/close.svg';
// Animation
const navVariants = {
  closed: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
  open: {
    opacity: 1,
    transition: {
      duration: 0.4,
      delay: 0.3,
    },
  },
};

const NavInner = () => {

  const { mainNav } = useNavQuery();

  useScrollFreeze();
  const { closeNav } = useSiteState();

  return (
    <motion.div
      className="h-screen mobile-nav w-screen bg-black text-white fixed top-0 bottom-0 left-0 z-3000 px-4 lg:px-gutter py-4 flex flex-col justify-between"
      initial="closed"
      animate="open"
      exit="closed"
      variants={navVariants}
    >
      <nav className="gap-y-0 flex flex-col text-3xl font-heading z-0">
        {mainNav.map((item) => (
          <NavLink
            activeClassName="text-doc-navActive"
            key={item._key}
            {...item}
            onClick={() => closeNav()}
          />
        ))}
      </nav>
      <Logo className="fill-white w-full h-auto" />
      <Close className="absolute top-4 right-4 z-10" onClick={() => closeNav()} />
    </motion.div>
  );
};

export const MobileNav = () => {
  const { navOpen } = useSiteState();
  return <AnimatePresence>{navOpen && <NavInner />}</AnimatePresence>;
};

