import { useStaticQuery, graphql } from 'gatsby';

export const useFooterQuery = () => {
  const { sanityFooterSettings } = useStaticQuery(graphql`
    query {
      sanityFooterSettings {
        businessEmail
        businessPhone
        businessAddress: _rawBusinessAddress(resolveReferences: { maxDepth: 10 })
        subscribeForm {
          heading
          placeholder
        }
        socialNav {
          _key
          name
          url
        }
        backToTop
      }
    }
  `);
  return sanityFooterSettings || {};
};
