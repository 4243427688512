import React from 'react';

// Hooks & Querys
import { useFooterQuery } from '../../../GraphQl/useFooterQuery';

// Components
import { ModuleWrapper } from '../moduleWrapper';
import { DotLogo } from '../../ui/dotLogo';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const ContactDetails = ({ heading, config }) => {

  const { businessEmail, businessAddress, businessPhone, socialNav } = useFooterQuery();

  return (
    <ModuleWrapper
      {...config}
      className={`gap-y-12 grid grid-cols-2 md:grid-cols-3 grid-flow-row lg:grid-cols-12 px-gutter`}
    >
      <div className="col-span-full lg:col-span-6 flex gap-x-4 items-baseline">
        <DotLogo />
        <div className="text-h3-mob lg:text-h3">{heading || 'Contact Us'}</div>
      </div>
      <div className="col-span-1 flex flex-col lg:col-span-2">
        <p className="text-xl mb-4">Social</p>
        {socialNav.map((item, index) => (
          <a key={index} href={item.url}>{item.name}</a>
        ))}
      </div>

      <div className="col-span-1 flex flex-col lg:col-span-2">
        <p className="text-xl mb-4">Office</p>
        <PortableTextBlock text={businessAddress} />
      </div>

      <div className="col-span-1 flex flex-col lg:col-span-2">
        <p className="text-xl mb-4">Contact</p>
        <a href={`tel:${businessPhone}`}>{businessPhone}</a>
        <a href={`mailto:${businessEmail}`}>{businessEmail}</a>
      </div>
    </ModuleWrapper>
  );
}

export default ContactDetails;
