import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import cx from 'classnames';
import useScrollPosition from '@react-hook/window-scroll';

// Hooks & State
import { useToggle } from '../../hooks';
import { useNavQuery } from '../../GraphQl/useNavQuery';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useSiteState } from '../../lib/navContext';

// Components
import { MobileNav } from './mobileNav';
import { MenuToggle } from './menuToggle';
import { NavLink } from './navLink';
import { HomeLinkMobile, HomeLinkDesktop, SiteLinkMobile, SiteLinkDesktop } from './homeLink';

export const Header = () => {
  const breakpoints = useBreakpoint();

  const [headerPinned, setHeaderPinned] = useState(false); // eslint-disable-line

  const { toggleNav, navOpen, homeHeader } = useSiteState();

  const [showMenu, setShowMenu] = useToggle(false);

  const [revealMenu, setRevealMenu] = useToggle(false);

  const { mainNav } = useNavQuery();

  const scrollY = useScrollPosition(5);

  useEffect(() => {
    if (headerPinned) {
      document.documentElement.classList.add('header-pinned');
    } else {
      document.documentElement.classList.remove('header-pinned');
    }
    setShowMenu(scrollY > 350);
    setRevealMenu(scrollY > 400);
  }, [headerPinned, setShowMenu, setRevealMenu, scrollY]);

  return (
    <>
      <header
        className={cx('site-header bg-doc-bg', {
          'nav-open': navOpen,
        })}
      >
        {homeHeader && !breakpoints.md && (
          <HomeLinkDesktop homeHeader={homeHeader} headerPinned={headerPinned} />
        )}
        {homeHeader && breakpoints.md && (
          <HomeLinkMobile headerPinned={headerPinned} navOpen={navOpen} />
        )}
        {!homeHeader && !breakpoints.md && <SiteLinkDesktop headerPinned={headerPinned} />}
        {!homeHeader && breakpoints.md && (
          <SiteLinkMobile headerPinned={headerPinned} navOpen={navOpen} />
        )}
        {breakpoints.md ? (
          <MobileNav />
        ) : (
          <motion.nav
            id="deskNav"
            className={`gap-x-10 xl:gap-x-14 text-base text-doc-text hidden transform transition-all duration-100 opacity-0 ${
              showMenu && 'lg:flex'
            } ${revealMenu && 'opacity-100'} ${!homeHeader && 'lg:flex opacity-100'}`}
          >
            {mainNav.map((item) => (
              <NavLink activeClassName="text-doc-navActive" key={item._key} {...item} />
            ))}
          </motion.nav>
        )}
        <div
          className={`${homeHeader && showMenu && 'block lg:hidden'} ${
            homeHeader && !showMenu && 'hidden'
          } ${revealMenu && 'opacity-100'} ${!homeHeader && 'block lg:hidden'}`}
        >
          <MenuToggle isOpen={navOpen} onClick={toggleNav} className="cursor-pointer" />
        </div>
      </header>
      {/* {homeHeader && (
        <div className="opacity-0 px-5 lg:px-gutter py-4 lg:py-8">
          <HomeLinkDesktop homeHeader={homeHeader} headerPinned={headerPinned} />
        </div>
      )} */}
    </>
  );
};
