import React from 'react'
import { scroller } from 'react-scroll'
import { NetlifyFormEmbed } from '../../forms/netlifyFormEmbed'
import { ModuleWrapper } from '../moduleWrapper'
import { ContactForm } from '../../forms/contactForm'

const FormModule = ({ selectedForm, config, layout }) => {
	if (typeof window !== 'undefined') {
		scroller.scrollTo('contact-form', {
			duration: 1000,
			delay: 800,
			smooth: 'easeInOutQuart',
		})
	}
	return (
		<>
			{selectedForm && (
				<ModuleWrapper {...config} className="grid grid-cols-14 gap-y-12">
					<div
						name="contact-form"
						className={` col-start-2 col-end-14 ${
							layout === 'left' ? 'lg:col-end-8' : 'lg:col-start-9'
						}`}
					>
						<ContactForm form={selectedForm} />
					</div>
				</ModuleWrapper>
			)}
		</>
	)
}

export default FormModule
