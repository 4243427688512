import React, { useEffect, useContext, createContext } from 'react'
import cx from 'classnames'
import { useInView } from 'react-intersection-observer'

const ModuleContext = createContext({ inView: false })

const ModuleWrapper = ({
	children,
	className,
	moduleBorderTop,
	paddingTop,
	paddingBottom,
	slug,
	triggerOnce = false,
	threshold = 0.3,
	moduleTheme,
}) => {
	// Padding Classes
	const wrapperClasses = cx({
		'pt-space-s': paddingTop === 'small',
		'pt-space-m': paddingTop === 'medium',
		'pt-space-l': paddingTop === 'large',
		'pb-space-s': paddingBottom === 'small',
		'pb-space-m': paddingBottom === 'medium',
		'pb-space-l': paddingBottom === 'large',
	})

	const { ref, inView } = useInView({
		/* Optional options */
		threshold: threshold,
		triggerOnce,
	})

	useEffect(() => {
		if (inView) {
			if (moduleTheme?.title === 'White' || moduleTheme?.title == undefined) {
				document.documentElement.style.setProperty(`--docBg`, `#EAE9E7`)
				document.documentElement.style.setProperty(`--docBgAlt`, `#fff`)
				document.documentElement.style.setProperty(`--docText`, `#000`)
				document.documentElement.style.setProperty(`--docNavActive`, `#A7A9AB`)
			} else if (moduleTheme?.title === 'Green') {
				document.documentElement.style.setProperty(
					`--docBg`,
					`${moduleTheme.value}`
				)
				document.documentElement.style.setProperty(`--docBgAlt`, `#E0F4E3`)
				document.documentElement.style.setProperty(`--docText`, `#000`)
				document.documentElement.style.setProperty(`--docNavActive`, `#fff`)
			} else {
				document.documentElement.style.setProperty(
					`--docBg`,
					`${moduleTheme.value}`
				)
				document.documentElement.style.setProperty(`--docBgAlt`, `#222`)
				document.documentElement.style.setProperty(`--docText`, `#fff`)
				document.documentElement.style.setProperty(`--docNavActive`, `#AFE5BA`)
			}
		}
	}, [moduleTheme, inView])

	return (
		<>
			{moduleBorderTop && (
				<div className="w-fullGutter mx-auto block h-1 border-t border-doc-text"></div>
			)}
			<section
				className={`${className} ${wrapperClasses}`}
				id={slug && slug.current}
				ref={ref}
			>
				<ModuleContext.Provider value={{ inView }}>
					{children}
				</ModuleContext.Provider>
			</section>
		</>
	)
}

const useModuleContext = () => useContext(ModuleContext)

export { ModuleWrapper, useModuleContext }
