import React from 'react';
import { ModuleWrapper } from '../moduleWrapper';
import { CardSlider } from '../../ui/cardSlider';
import { DotLogo } from '../../ui/dotLogo';
import { PageButton } from '../../ui/links';

// Staff Card
const StaffCard = ({ name, jobTitle, text, photo }) => {
  return (
    <div>
      <div className="group aspect-w-10 aspect-h-12 relative">
        {photo ? (
          <img
          src={photo.asset.url}
          alt={photo.alt}
          className="block absolute inset-0 object-cover"
        />
        ) : (
          <div className="block absolute inset-0 object-cover"></div>
        )}        
        {text && (
          <div className="z-10 absolute inset-0 bg-bc-green text-black p-4 transition-all duration-200 opacity-0 group-hover:opacity-100">
            {text}
          </div>
        )}
      </div>
      <div class="w-full flex justify-between align-center mt-2 leading-heading lg:text-h4">
        <div className="w-1/2 pr-2">{name && name}</div>
        <div className="w-1/2 pl-2">{jobTitle && jobTitle}</div>
      </div>
    </div>
  );
};

// Main Component
const StaffSet = ({ config, heading, staffMembers, callToAction, layout }) => {
  

  return (
    <ModuleWrapper {...config} className={`grid grid-cols-14 gap-y-20 lg:gap-y-40`}>
      {/* Header */}
      <div className="col-start-2 col-end-14 row-start-1">
        <div className="flex gap-x-4 items-center">
          <DotLogo />
          <div className="text-h3-mob lg:text-h3">{heading}</div>
        </div>
      </div>
      {/* Call To Action Button */}
      {callToAction.showButton === true && (
        <div className="row-start-3 lg:row-start-1 col-start-2 col-end-14 lg:col-start-10 lg:flex lg:justify-end">
          <PageButton page={callToAction.page} linkText={callToAction.linkText} />
        </div>
      )}
      {/* Staff Set */}
      {layout === 'slider' ? (
        <div className="col-start-2 col-end-14">
          <CardSlider>
            {staffMembers.map((staffMember, index) => {
              return (
                <div
                  key={staffMember._key}
                  className="w-full md:w-1/2 lg:w-1/3 md:px-8"
                  style={{ flex: '0 0 auto' }}
                >
                  <StaffCard {...staffMember} key={index} />
                </div>
              );
            })}
          </CardSlider>
        </div>
      ) : (
        <div class="col-start-2 col-end-14 lg:col-start-3 lg:col-end-13 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-24 gap-x-16">
          {staffMembers.map((staffMember, index) => {
            return (
              <>
              {staffMember.name && (
                <div className="col-span-1 w-full">
                <StaffCard {...staffMember} key={index} />
              </div>
              )}
              </>
            );
          })}
        </div>
      )}
    </ModuleWrapper>
  );
};

export default StaffSet;
