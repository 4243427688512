import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Link } from 'gatsby';

// Hooks & Querys
import { useSanityPath } from '../../hooks';

export const PageLink = ({ classNames, page, linkText, ...rest }) => {
  const path = useSanityPath(page);
  return (
    <Link className={classNames} to={path} {...rest}>
      {linkText}      
    </Link>
  );
};

export const PageButton = ({ classNames, page, linkText, ...rest }) => {
  const path = useSanityPath(page);
  return (
    <Link className={`button-standard ${classNames}`} to={path} {...rest}>
      {linkText}
    </Link>
  );
};

export const CtaButton = ({ classNames, page, linkText, ...rest }) => {
  return (
    <AnchorLink stripHash className={`button-standard ${classNames}`} to={`/contact#form`} {...rest}>
      {linkText}
    </AnchorLink>
  );
};

export const PathLink = ({ path, linkText, ...rest }) => {
  if (path.includes('http')) {
    return (
      <a href={path} target="_blank" rel="noreferrer">
        {linkText}
      </a>
    )
  } else {
    return (
      <Link to={`/${path}`} {...rest}>
        {linkText}
      </Link>
    );
  }
};

export const PageAnchorLink = ({ anchor, page, linkText, ...rest }) => {
  const path = useSanityPath(page);
  return (
    <AnchorLink to={`/${path}#${anchor}`} {...rest}>
      {linkText}
    </AnchorLink>
  );
};

export const AnchorScrollLink = ({ anchor, linkText, children, ...rest }) => {
  return (
    <ScrollLink to={anchor} offset={-100} smooth={true} {...rest}>
      {children || linkText}
    </ScrollLink>
  );
};

export const ExternalLink = ({ linkText, link, ...rest }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" {...rest}>
      {linkText}
    </a>
  );
};

export const SwitchLink = ({ _type, ...rest }) => {
  const LinkComponent = () => {
    switch (_type) {
      case 'pageLink':
        return <PageLink {...rest} />;
      case 'path':
        return <PathLink {...rest} />;
      case 'externalLink':
        return <ExternalLink {...rest} />;
      default:
        return null;
    }
  };
  return <LinkComponent {...rest} />;
};
