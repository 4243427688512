import React, { useRef, useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
// import useFormValidation from "../../hooks/useFormValidation"
import { useLocation } from '@reach/router'

import { PortableTextBlock } from '../sanity/portableTextBlock'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const NetlifyFormEmbed = ({ form, className, successText }) => {
  const inputClasses = 'styled-input'

  const labelClasses = 'hidden'

  const errorClasses = 'tracking-wide text-sm my-2'

  const { pathname } = useLocation()

  const { _rawSuccessText } = form

  const formName = 'Contact'

  // const event = new CustomEvent('onSubmitForm', {
  //   detail: formName,
  // })
  const eventRef = useRef(null)
  useEffect(() => {
    eventRef.current = new CustomEvent('onSubmitForm', {
      detail: formName,
    })
  }, [])

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        referral: '',
        subscribe: true,
      }}
      validate={(values) => {
        const errors = {}
        if (!values.firstName) {
          errors.firstName = 'First Name is Required'
        }
        if (!values.lastName) {
          errors.lastName = 'Last Name is Required'
        }
        if (!values.email) {
          errors.email = 'Email address is Required'
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address'
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        fetch(pathname || '/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': `Contact`,
            ...values,
          }),
        })
          .then(() => {
            if (eventRef.current) {
              document.dispatchevent(eventRef.current)
            }
            setSubmitting(false)
            setStatus('success')
          })
          .catch((error) => alert(error))
      }}
    >
      {({
        onChange,
        isSubmitting,
        status,
        values,
        /* and other goodies */
      }) => (
        <Form
          className={`${className}`}
          name="Contact"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          subject={`Contact Form`}
        >
          {/* Hidden Fields for netlify */}
          <input type="hidden" name="form-name" value="Contact" />
          <p hidden>
            <label>
              <input name="bot-field" />
            </label>
          </p>
          <article className="flex flex-col gap-y-6">
            <div className="col-span-1">
              <label className={labelClasses} htmlFor="firstName">
                My first name
              </label>
              <Field
                type="text"
                name="firstName"
                className={inputClasses}
                placeholder="First Name"
              />
              <ErrorMessage
                name="firstName"
                className={errorClasses}
                component="div"
              />
            </div>

            <div className="col-span-1">
              <label className={labelClasses} htmlFor="lastName">
                My last name
              </label>
              <Field
                type="text"
                name="lastName"
                className={inputClasses}
                placeholder="Last Name"
              />
              <ErrorMessage
                name="lastName"
                className={errorClasses}
                component="div"
              />
            </div>

            <div className="col-span-1">
              <label className={labelClasses} htmlFor="phone">
                My phone
              </label>
              <Field
                type="text"
                name="phone"
                className={inputClasses}
                placeholder="Phone"
              />
              <ErrorMessage
                name="phone"
                className={errorClasses}
                component="div"
              />
            </div>

            <div className="col-span-1">
              <label className={labelClasses} htmlFor="email">
                My email
              </label>
              <Field
                type="text"
                name="email"
                className={inputClasses}
                placeholder="Email Address"
              />
              <ErrorMessage
                name="email"
                className={errorClasses}
                component="div"
              />
            </div>

            <div>
              <Field
                component="select"
                name={`referral`}
                className="styled-select"
              >
                <option>How did you hear about us?</option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Advert">Advert</option>
                <option value="Google">Google</option>
                <option value="Other">Other</option>
              </Field>
            </div>
            <div className="col-span-1">
              <label className={labelClasses} htmlFor="email">
                My first name
              </label>
              <Field
                component="textarea"
                name="message"
                rows="6"
                className={'styled-textarea'}
                placeholder="Enquiry*"
              />
              <ErrorMessage
                name="email"
                className={errorClasses}
                component="div"
              />
            </div>

            <div className="col-span-1 flex items-center">
              <Field
                type="checkbox"
                name="subscribe"
                className="form-checkbox border border-doc-text bg-white text-black w-6 h-6 outline-none"
              />
              <label htmlFor="subscribe" name="subscribe" className="">
                <span className="ml-2">
                  I would like to receive email updates
                </span>
              </label>
            </div>

            <div>
              <button className="button-submit" type="submit">
                Send
              </button>
            </div>
            {status === 'success' && (
              <section>
                {_rawSuccessText ? (
                  <PortableTextBlock text={_rawSuccessText} />
                ) : (
                  <h4>Thank you, we will be in touch.</h4>
                )}
              </section>
            )}
          </article>
        </Form>
      )}
    </Formik>
  )
}
