import React from 'react'
import PortableText from 'react-portable-text'

// Block Components
import { ExternalLink, PageButton, PathLink } from '../ui/links'
import { SanityImage } from '../media/sanityImage'
import { VideoModule } from '../pageModules/modules/videoModule'

const serializers = {
	h1: (props) => <h1 {...props} className="font-display" />, // eslint-disable-line
	h2: (props) => <h2 {...props} className="font-display" />, // eslint-disable-line
	h3: (props) => <h3 {...props} className="font-display" />, // eslint-disable-line
	h4: (props) => <h4 {...props} className="font-display" />, // eslint-disable-line
	h5: (props) => <h5 {...props} className="font-display" />, // eslint-disable-line
	h6: (props) => <h6 {...props} className="font-display" />, // eslint-disable-line
	'super-script': (props) => <sup>{props.children}</sup>,
	'sub-script': (props) => <sub>{props.children}</sub>,
	pageLink: (props) => <PageButton {...props} />,
	pathLink: (props) => <PathLink {...props} className="underline" />,
	externalLink: (props) => <ExternalLink {...props} className="underline" />,
	normal: (props) => <p {...props} />,
	strong: (props) => <span {...props} className="font-display-med" />,
	image: (props) => <SanityImage image={props} />,
	videoModule: (props) => <VideoModule video={props} />,
} // eslint-disable-line

export const PortableTextBlock = ({ text, ...props }) => {
	return <PortableText content={text} serializers={serializers} {...props} />
}
