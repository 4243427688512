import React from 'react';
import { Link } from 'gatsby';

// Hooks & Querys
import { useFooterQuery } from '../../GraphQl/useFooterQuery';

// SVG
import Logo from '../../svg/logoStacked.svg';

// Components
import { SubscribeForm } from '../forms/subscribeForm';
import { PortableTextBlock } from '../sanity/portableTextBlock';

export const Footer = () => {
  const { businessEmail, businessAddress, businessPhone, socialNav, backToTop, subscribeForm } = useFooterQuery();

  return (
    <footer>
      {/* Footer Bottom */}
      <div className="bg-black text-white px-gutter py-16 gap-y-12 grid grid-cols-2 md:grid-cols-3 grid-flow-row lg:grid-cols-12">
        {/* Logo */}
        <div className="col-span-full lg:col-span-6">
          <Link to="/">
            <Logo className="w-28 lg:w-64 h-auto text-white fill-current" />
          </Link>
        </div>
        <div className="col-span-1 flex flex-col lg:col-span-2">
          <p className="text-xl mb-4">Social</p>
          {socialNav.map((item, index) => (
            <a key={item._key} href={item.url} target="_blank" rel="noreferrer">
              {item.name}
            </a>
          ))}
        </div>
        
         <div className="col-span-1 flex flex-col lg:col-span-2">
          <p className="text-xl mb-4">Office</p>
          <PortableTextBlock text={businessAddress} />
        </div>
        <div className="col-span-1 flex flex-col lg:col-span-2">
          <p className="text-xl mb-4">Contact</p>
          <a href={`tel:${businessPhone}`}>{businessPhone}</a>
          <a href={`mailto:${businessEmail}`}>{businessEmail}</a>
        </div>       

        <div className="col-span-full lg:row-start-3 lg:col-start-7 lg:col-end-13 text-white">
          <SubscribeForm {...subscribeForm} />
        </div>

        <div className="col-span-full flex justify-between lg:flex-col-reverse lg:row-start-3 lg:col-start-1 lg:col-end-3">
          <p className="">&copy; 2021</p>
          {backToTop && (
            <Link className="text-bc-green" to={`/`}>
              Back to top
            </Link>
          )}
        </div>
      </div>
    </footer>
  );
};
