const defaultTransition = {
  duration: 0.3,
  ease: 'easeOut',
};

const navVariants = {
  closed: {
    opacity: 0,
    x: '100%',
    transition: {
      ...defaultTransition,
      when: 'afterChildren',
    },
  },
  open: {
    opacity: 1,
    x: '0%',
    transition: {
      ...defaultTransition,
      when: 'beforeChildren',
    },
  },
};

const mountFade = {
  closed: {
    opacity: 0,
    transition: {
      ...defaultTransition,
      when: 'afterChildren',
    },
  },
  open: {
    opacity: 1,
    transition: {
      ...defaultTransition,
      when: 'beforeChildren',
    },
  },
};

const cartDrawReveal = {
  open: {
    x: '0%',
    transition: {
      ...defaultTransition,
    },
  },
  closed: {
    x: '100%',
    transition: {
      ...defaultTransition,
    },
  },
};

const accordionReveal = {
  open: { opacity: 1, height: 'auto', transition: { ...defaultTransition } },
  closed: { opacity: 0, height: '0px', transition: { ...defaultTransition } },
};

export { defaultTransition, navVariants, mountFade, accordionReveal, cartDrawReveal };
