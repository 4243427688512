import React from 'react'
import loadable from '@loadable/component'

const VideoComponent = loadable(() => import('../../media/standardVideo'))

export function VideoModule({ video }) {
	return (
		<div className="relative wrapper">
			<VideoComponent
				url={video.video}
				playing={true}
				muted={true}
				loop={true}
				controls={false}
				width="100%"
				height="auto%"
				playsinline={true}
				className="iframe-video"
			/>
		</div>
	)
}
