import React, { useCallback } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import cx from 'classnames';
import { ArrowButton } from '../../ui/arrowButton';

// Components
import { ModuleWrapper } from '../moduleWrapper';
import { DotLogo } from '../../ui/dotLogo';
import { PortableTextBlock } from '../../sanity/portableTextBlock';


// Value Card
const ValueCard = ({ index, title, textContent }) => {
  return (
    <div>
      <div className="text-h3 font-medium mb-4">0{index + 1}</div>
      <div className="text-bg-darkAlt mb-2 leading-heading lg:text-h4">{title && title}</div>
      {textContent && <PortableTextBlock text={textContent} className="prose lg:text-h4" />}      
    </div>
  );
};

// Main Component
const Values = ({ heading, valueSections, config }) => {
  const breakpoints = useBreakpoint();

  // Embla Setup
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    dragFree: false,
    align: 'start',
    containScroll: true,
  });

  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  return (
    <ModuleWrapper {...config} className="grid grid-cols-14 overflow-x-hidden">
      {/* Header */}
      <div className="col-start-2 col-end-14 mb-24 lg:mb-36 flex justify-between">
        <div className="flex gap-x-4 items-center">
          <DotLogo />
          <div className="font-medium text-h3-mob lg:text-h3">{heading}</div>
        </div>
      </div>
      {/* Values Desktop */}
      {!breakpoints.sm ? (
        <div className="col-start-2 col-end-14 grid grid-cols-5 gap-x-4 xl:gap-x-5 gap-y-10">
          {valueSections.map((valueItem, index) => {
            return <ValueCard {...valueItem} index={index} key={index} />;
          })}
        </div>
      ) : (
        <div ref={emblaRef} className="relative col-start-2 col-end-14 overflow-x-hidden">
          <div className="flex gap-x-4">
            {valueSections.map((valueItem, index) => {
              return (
                <div
                  key={valueItem._key}
                  className="w-full md:w-1/2 lg:w-1/3"
                  style={{ flex: '0 0 auto' }}
                >
                  <ValueCard {...valueItem} index={index} key={index} />
                </div>
              );
            })}
          </div>
          <ArrowButton
            className={cx(
              'absolute top-1/2 transform -translate-y-1/2 right-0 transition-colors duration-200 md:hidden'
            )}
            direction="right"
            onClick={scrollNext}
          />
        </div>
      )}
    </ModuleWrapper>
  );
};

export default Values;
