import React, { useCallback, useState, useEffect } from 'react'
import cx from 'classnames'
import { useEmblaCarousel } from 'embla-carousel/react'
import { ModuleWrapper } from '../moduleWrapper'
import { PortableTextBlock } from '../../sanity/portableTextBlock'

const GalleryText = ({
  config,
  featuredHeading,
  textContent,
  galleryItems,
  layout,
}) => {
  const hasHeading = featuredHeading != null

  // Embla Setup
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    dragFree: false,
    align: 'start',
    containScroll: true,
    speed: 20,
  })

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [slideIndex, setSlideIndex] = useState(0)

  const onSelect = useCallback(() => {
    if (!emblaApi) return
    setPrevBtnEnabled(emblaApi.canScrollPrev())
    setNextBtnEnabled(emblaApi.canScrollNext())
  }, [emblaApi])

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  )
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  )

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    emblaApi.on('select', onSelect)
    emblaApi.on('select', (event) => {
      setSlideIndex(emblaApi.selectedScrollSnap())
    })
  }, [emblaApi, onSelect])

  const headingClasses = cx({
    'text-h3-mob md:text-h4 lg:text-h3 leading-heading col-start-4 col-end-14 md:col-start-7': true,
    'row-start-1': hasHeading,
    'lg:col-start-2 lg:col-end-8 xl:col-end-7': layout === 'right',
    'lg:col-start-8 lg:col-end-14 lg:pr-10': layout === 'left',
    'lg:row-start-1': hasHeading,
  })

  const textClasses = cx({
    'prose lg:prose-lg col-start-2 col-end-14 max-w-none lg:row-start-1': true,
    'row-start-3 lg:row-start-2': hasHeading,
    'lg:col-start-2 lg:col-end-7': layout === 'right' && !hasHeading,
    'lg:col-start-8 xl:col-start-8 lg:col-end-14':
      layout === 'left' && !hasHeading,
    'lg:col-start-2 xl:col-start-3 lg:col-end-8 2xl:col-end-7':
      layout === 'right' && hasHeading,
    'lg:col-start-8 xl:col-start-9 lg:col-end-14 2xl:col-end-13':
      layout === 'left' && hasHeading,
    'lg:row-start-2': hasHeading,
  })

  const galleryClasses = cx({
    'col-start-2 col-end-14 lg:row-start-1': true,
    'lg:col-start-8 lg:col-end-15 lg:pl-16': layout === 'right',
    'lg:col-start-1 lg:col-end-8 lg:pr-16': layout === 'left',
  })

  return (
    <ModuleWrapper
      {...config}
      className={`grid grid-cols-14 gap-y-16 lg:gap-y-0`}
    >
      {featuredHeading && (
        <div className={headingClasses}>{featuredHeading}</div>
      )}
      {textContent && (
        <PortableTextBlock text={textContent} className={textClasses} />
      )}
      <div className={galleryClasses}>
        <div ref={emblaRef} className="overflow-x-hidden">
          <div className="flex">
            {galleryItems.map((item) => (
              <div
                key={item._key}
                className="w-full"
                style={{ flex: '0 0 auto' }}
              >
                <div className="w-full relative aspect-w-7 aspect-h-10 lg:aspect-w-11 lg:aspect-h-10">
                  <img
                    src={item.asset.url}
                    alt={item?.alt}
                    className="absolute inset-0 object-cover"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={`mt-1.5 w-full flex justify-between ${
            layout === 'left' ? 'lg:pl-gutter' : 'lg:pr-gutter'
          }`}
        >
          {/* Caption */}
          <div>
            <span className={galleryItems.length < 2 ? '' : 'hidden'}>
              {slideIndex + 1} /{' '}
            </span>
            {galleryItems[slideIndex]?.alt}
          </div>
          {/* Nav */}
          <div className={`flex gap-3 ${galleryItems.length < 2 && 'hidden'}`}>
            <div
              className={`z-10 transition-colors duration-75 cursor-pointer ${
                !prevBtnEnabled && 'opacity-0'
              }`}
              direction="right"
              onClick={scrollPrev}
              onKeyDown={scrollPrev}
              disabled={!prevBtnEnabled}
              role="button"
              aria-pressed="false"
              tabIndex="0"
            >
              Previous
            </div>
            <div
              className={`z-10 transition-colors duration-75 cursor-pointer ${
                !nextBtnEnabled && 'opacity-50'
              }`}
              direction="right"
              onClick={scrollNext}
              onKeyDown={scrollNext}
              disabled={!nextBtnEnabled}
              role="button"
              aria-pressed="false"
              tabIndex="0"
            >
              Next
            </div>
          </div>
        </div>
      </div>
    </ModuleWrapper>
  )
}

export default GalleryText
