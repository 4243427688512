import React from 'react';
import Marquee from 'react-fast-marquee';
import { ModuleWrapper } from '../moduleWrapper';
import { CardSlider } from '../../ui/cardSlider';
import { DotLogo } from '../../ui/dotLogo';
import { PageButton } from '../../ui/links';

// Staff Card
const TestimonialCard = ({ name, company, text, photo, layout }) => {
  return (
    <>
      {name && (
        <div>
          <div className="group aspect-w-10 aspect-h-12 relative">
            <img
              src={photo.asset.url}
              alt={photo.alt}
              className="block absolute inset-0 object-cover"
            />
            {text && (
              <div className="z-10 absolute inset-0 bg-bc-green text-black p-4 transition-all duration-200 opacity-0 group-hover:opacity-100">
                {text}
              </div>
            )}
          </div>
          <div class="w-full flex justify-between align-center mt-2 gap-x-4 leading-heading lg:text-h4">
            {company && <div>{company}</div>}
            {name && <div>{name}</div>}
          </div>
        </div>
      )}
    </>
  );
};

// Main Component
const TestimonialSlider = ({ config, heading, sliderItems, callToAction, layout }) => {
  return (
    <ModuleWrapper {...config} className={`grid grid-cols-14 gap-y-16 lg:gap-y-40`}>
      {layout === 'slider' ? (
        <>
          <div className="col-start-2 col-end-14 row-start-1">
            <div className="flex gap-x-4 items-center">
              <DotLogo />
              <div className="text-h3-mob lg:text-h3">{heading}</div>
            </div>
          </div>
          {/* Call To Action Button */}
          {callToAction.showButton === true && (
            <div className="row-start-3 lg:row-start-1 col-start-2 col-end-14 lg:col-start-10 lg:flex lg:justify-end">
              <PageButton page={callToAction.page} linkText={callToAction.linkText} />
            </div>
          )}
          {/* Staff Set */}
          <div className="col-start-2 col-end-14">
            <CardSlider>
              {sliderItems.map((testimonial, index) => {
                return (
                  <div
                    key={testimonial._key}
                    className="w-full md:w-1/2 lg:w-1/3 md:px-8"
                    style={{ flex: '0 0 auto' }}
                  >
                    <TestimonialCard {...testimonial} />
                  </div>
                );
              })}
            </CardSlider>
          </div>
        </>
      ) : (
        <div className="col-start-1 col-end-15 transition-colors duration-300 w-full block bg-doc-bgAlt bg-opacity-50 text-doc-text card-box-shadow">
          <Marquee gradient={false} speed={40}>
            {sliderItems.map((testimonial) => {
              const { text, _key } = testimonial;
              return (
                <div key={_key} className="py-4 px-8 lg:py-4 border-r border-bg-grey">
                  {text}
                </div>
              );
            })}
          </Marquee>
        </div>
      )}
    </ModuleWrapper>
  );
};

export default TestimonialSlider;
