import { useStaticQuery, graphql } from 'gatsby';

import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

export const useNavQuery = () => {
  const { sanityNavSettings } = useStaticQuery(graphql`
    query {
      sanityNavSettings {
        mainNav {
          ... on SanityPageLink {
            _key
            _type
            ...PageLink
          }
          ... on SanityPathLink {
            _key
            _type
            ...PathLink
          }
        }
      }
    }
  `);
  return sanityNavSettings || {};
};
