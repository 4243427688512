import React from 'react';
import loadable from '@loadable/component';
import { motion, useAnimation } from 'framer-motion';
import { ModuleWrapper } from '../moduleWrapper';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const videoVariants = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
    transition: {
      ease: 'easeOut',
      duration: 0.4,
      delay: 0.3,
    },
  },
};

const VideoComponent = loadable(() => import('../../media/standardVideo'));

const HeroVideo = ({ videoMobile, videoDesktop, fileDesktop, fileMobile, config }) => {

  const breakpoints = useBreakpoint();

  const animationControls = useAnimation();

  return (
    <ModuleWrapper
      {...config}
      className="grid grid-cols-14 px-gutter pb-12 lg:pb-20 mt-40 md:mt-48 lg:mt-64 xl:mt-96"
    >
      <motion.div        
        initial="hidden"
        animate={animationControls}
        variants={videoVariants}
        className="col-span-full bg-transparent text-gray-700 flex items-center justify-center text-3xl relative"
      >
        {breakpoints.md ? (
          <VideoComponent
            url={fileMobile.asset.url}
            playing={true}
            muted={true}
            loop={true}
            controls={false}
            width="100%"
            height="auto"
            playsinline={true}
          />
        ) : (
          <VideoComponent
            url={fileDesktop.asset.url}
            playing={true}
            muted={true}
            loop={true}
            controls={false}
            width="100%"
            height="auto"
            playsinline={true}
          />
        )}
        {/* <img src="/video.gif" className="absolute inset-0 w-full h-full object-cover" /> */}
      </motion.div>
    </ModuleWrapper>
  );
};

export default HeroVideo
